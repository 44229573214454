import { render, staticRenderFns } from "./BrokerSettings.vue?vue&type=template&id=475add16&scoped=true"
import script from "./BrokerSettings.vue?vue&type=script&lang=js"
export * from "./BrokerSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475add16",
  null
  
)

export default component.exports