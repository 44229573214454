<template>
  <div class="header">
    <v-row class="title-row">
      <h3 class="header-title">{{ $t("Settings") }}</h3>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DashboardHeader",
  components: {
  },
  data() {
    return {
    };
  },
  created() {},
  mounted() {},
  watch: {
  },
  computed: {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: white;
}
</style>
