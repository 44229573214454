<template>
  <div>
    <Header/>
    <BrokerSettingsContent/>
  </div>
</template>

<script>
import Header from "@/components/Admin/BrokerSettingsHeader.vue";
import BrokerSettingsContent from "@/components/Admin/BrokerSettingsContent";
export default {
  name: "BrokerSettings",
  components: {
    Header,
    BrokerSettingsContent,
  },
}
</script>

<style lang="scss" scoped>

</style>