<template>
  <vue-draggable-resizable
      :z="101"
      :resizable="false"
      drag-handle=".drag-area"
      class="widget-container"
      @dragstop="onDragStop"
      v-click-outside="closePanel"
  >
    <div class="change-password-widget">
      <div class="widget-header drag-area">
        <div>
          <img
              src="@/assets/images/menu.svg"
              alt=""
          />
        </div>
        <div class="close-button">
          <img
              src="@/assets/images/close_grey.svg"
              alt=""
              @click="closePanel"
          />
        </div>
      </div>
      <v-col class="form-col">
      <h4>
        {{ $t("ChangePassword") }}
      </h4>
        <div class="widget-fields">
          <div class="toggle-wrapper">
            <img
                @click.stop="clickOnToggle"
                style="cursor: pointer"
                :src="
                      showPassword
                        ? require('../../assets/images/toggle-active.svg')
                        : require('../../assets/images/toggle-inactive.svg')
                    "
                alt=""
            />
            <p class="content" @click.stop="clickOnToggle">{{ this.$t('ShowPassword').toLowerCase() }}</p>
          </div>
          <BaseInput
              v-model="oldPassword"
              :id="'old-password'"
              :label="$t('CurrentPassword')"
              :type="passwordFieldType"
              :placeholder="$t('CurrentPassword')"
              :icon-left="'lock.svg'"
              ref="passwordInput"
              :password-fields="true"

          />
          <BaseInput
              v-model="newPassword"
              :label="$t('NewPassword')"
              :id="'new-password'"
              :type="passwordFieldType"
              :placeholder="$t('NewPassword')"
              :icon-left="'lock.svg'"
              :password-fields="true"

          />
          <BaseInput
              v-model="confirmPassword"
              :label="$t('ConfirmNewPassword')"
              :id="'confirm-password'"
              :type="passwordFieldType"
              :placeholder="$t('ConfirmNewPassword')"
              :icon-left="'lock.svg'"
              :password-fields="true"

          />
        </div>
      </v-col>
      <v-row class="widget-footer">
        <CancelButton
            @clicked="closePanel"
            type="button"
            class="widget-cancel-button">
        </CancelButton>
        <SubmitButton
            :icon="false"
            @clicked="changePassword">
        </SubmitButton>
      </v-row>

    </div>

  </vue-draggable-resizable>
</template>

<script>
import BaseInput from '@/components/common/BaseInput.vue';
import VueDraggableResizable from "vue-draggable-resizable";
import Admin from "@/http/admin";
import CancelButton from "../common/CancelButton";
import SubmitButton from "../common/SubmitButton";

export default {
  name: "ChangePasswordModal",
  props: [
    'broker',
  ],
  components: {
    SubmitButton,
    CancelButton,
    VueDraggableResizable,
    BaseInput,
  },
  data() {
    return {
      posX: 0,
      posY: 0,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showPassword: false,
    }
  },
  created() {
  },
  computed: {
    passwordFieldType() {
      if(this.showPassword) {
        return 'text'
      } else {
        return 'password'
      }
    }
  },
  beforeDestroy() {},
  methods: {
    onDragStop(left, top) {
      this.posX = left;
      this.posY = top;
    },
    closePanel() {
      this.$emit('close-modal')
    },
    clickOnToggle() {
      this.showPassword = !this.showPassword
    },
    async changePassword(){
      let changePass = {
        email: this.broker.email,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword
      }
      if(this.newPassword === this.confirmPassword){
        if(this.newPassword.length < 8){
          this.toastError(this.$t('FormErrors.Password8Characters'))
          return
        }
        let response = await Admin.changePassword(changePass);
        if(response === 1){
          this.$emit('close-modal')
          this.toastSuccess(this.$t('ChangePasswordSuccess'));
        }
        if(response === 0){
          this.toastError(this.$t('ChangePasswordFailedWrongCurrentPassword'))
        }
      } else {
        this.toastError(this.$t('ChangePasswordFailedNewAndConfirmPasswordNotMatch'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-container {
  position: fixed;
  left: 34%;
  top: 26% !important;;
  padding-bottom: 20px;
  padding-right: 40px;
}
.change-password-widget {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11), 0px 0px 0px rgba(0, 0, 0, 0.1);
  width: 400px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  position: fixed;
  background: white;
  color: white !important;
  border-radius: 8px;
}
.form-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
}
.drag-area {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
}
.close-button {
  cursor: pointer;
  padding: 2px 16px 0 0;
  margin: auto 0;
}
.widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  width: 400px;
  background-color: #F4F5F7;
  border-radius: 8px 8px 0 0;
}
.widget-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 336px;
}
.widget-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  gap: 8px;
  height: 88px;
  width: 100%;
}
.toggle-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.widget-cancel-button {
  color: black;
}
</style>