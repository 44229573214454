var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 101,
      "resizable": false,
      "drag-handle": ".drag-area"
    },
    on: {
      "dragstop": _vm.onDragStop
    }
  }, [_c('div', {
    staticClass: "change-password-widget"
  }, [_c('div', {
    staticClass: "widget-header drag-area"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "close-button"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/close_grey.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.closePanel
    }
  })])]), _c('v-col', {
    staticClass: "form-col"
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t("ChangePassword")) + " ")]), _c('div', {
    staticClass: "widget-fields"
  }, [_c('div', {
    staticClass: "toggle-wrapper"
  }, [_c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": _vm.showPassword ? require('../../assets/images/toggle-active.svg') : require('../../assets/images/toggle-inactive.svg'),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.clickOnToggle.apply(null, arguments);
      }
    }
  }), _c('p', {
    staticClass: "content",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.clickOnToggle.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(this.$t('ShowPassword').toLowerCase()))])]), _c('BaseInput', {
    ref: "passwordInput",
    attrs: {
      "id": 'old-password',
      "label": _vm.$t('CurrentPassword'),
      "type": _vm.passwordFieldType,
      "placeholder": _vm.$t('CurrentPassword'),
      "icon-left": 'lock.svg',
      "password-fields": true
    },
    model: {
      value: _vm.oldPassword,
      callback: function ($$v) {
        _vm.oldPassword = $$v;
      },
      expression: "oldPassword"
    }
  }), _c('BaseInput', {
    attrs: {
      "label": _vm.$t('NewPassword'),
      "id": 'new-password',
      "type": _vm.passwordFieldType,
      "placeholder": _vm.$t('NewPassword'),
      "icon-left": 'lock.svg',
      "password-fields": true
    },
    model: {
      value: _vm.newPassword,
      callback: function ($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  }), _c('BaseInput', {
    attrs: {
      "label": _vm.$t('ConfirmNewPassword'),
      "id": 'confirm-password',
      "type": _vm.passwordFieldType,
      "placeholder": _vm.$t('ConfirmNewPassword'),
      "icon-left": 'lock.svg',
      "password-fields": true
    },
    model: {
      value: _vm.confirmPassword,
      callback: function ($$v) {
        _vm.confirmPassword = $$v;
      },
      expression: "confirmPassword"
    }
  })], 1)]), _c('v-row', {
    staticClass: "widget-footer"
  }, [_c('CancelButton', {
    staticClass: "widget-cancel-button",
    attrs: {
      "type": "button"
    },
    on: {
      "clicked": _vm.closePanel
    }
  }), _c('SubmitButton', {
    attrs: {
      "icon": false
    },
    on: {
      "clicked": _vm.changePassword
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }